<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />
    <b-card>
      <div
        v-if="$route.params.userId"
        class="head-card"
      >
        <div class="head-card-top">
          <h4>{{ $t('Landings') }}</h4>
          <feather-icon
            v-b-tooltip.hover.top="$t('Close')"
            icon="XIcon"
            size="18"
            class="toggle-close cursor-pointer"
            @click="$router.go(-1)"
          />
        </div>
        <hr>
      </div>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <div
        v-else-if="rows.length !== 0"
        class="campaigns-table"
      >
        <div class="custom-search">
          <div class="total">
            <strong>{{ $t('campaigns.campaigntotal') }}: {{ totals }}</strong>
          </div>
        </div>

        <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :sort-options="{
            enabled: true,
            initialSortBy: {field: 'status', type: 'asc'}
          }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          style-class="vgt-table striped"
        >
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span
              v-if="props.column.label ==='Status'"
              class="text-nowrap"
            >
              {{ $t('campaigns.status') }}
            </span>
            <span
              v-else-if="props.column.label ==='Name'"
              class="text-nowrap"
            >
              {{ $t('campaigns.title') }}
            </span>
          </template>
          <!-- Slot: Table Row -->
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Status -->
            <span
              v-if="props.column.field === 'title'"
              class="text-nowrap"
            >
              {{ props.row.title }}
              <b-badge :variant="status[props.row.status]">
                {{ $t(`selects.statuses.${props.row.status}`) }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'action'">
              <span class="d-flex justify-content-center">
                <b-button
                  v-b-tooltip.hover.top="$t('form.change')"
                  :to="'/users/'+ props.row.user_id + '/landings/'+ props.row._id + '/edit/'"
                  variant="flat"
                  class="btn-action"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                  />
                </b-button>
              </span>
            </span>
          </template>
        </vue-good-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import store from '@/store/index'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import {
  BCard,
  BBadge,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import landing from './landingData'

export default {
  components: {
    VueGoodTable,
    Breadcrumbs,
    UserToolbar,
    BCard,
    BBadge,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      authUserData: getUserData(),
      account: {},
      loading: false,
      pageLength: 20,
      dir: false,
      columns: [
        {
          label: 'Name',
          field: 'title',
        },
        {
          label: 'Status',
          field: 'status',
          width: '9%',
          type: 'boolen',
          hidden: true,
        },
        {
          label: '_id',
          field: '_id',
          hidden: true,
        },
        {
          label: 'user_id',
          field: 'user_id',
          hidden: true,
        },
        {
          label: 'Action',
          field: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          width: '5%',
          sortable: false,
        },
      ],
      breadcrumbItems: [],
      rows: landing,
      status: {
        active: 'light-success',
        inactive: 'light-danger',
        is_moderate: 'light-warning',
      },
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        negative     : 'outline-primary',
        active       : 'outline-success',
        inactive     : 'outline-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    totals() {
      return this.rows.length
    },
  },
  created() {
    this.getUserData()
  },
  methods: {
    setBreabcrumbs() {
      if (this.$route.params.userId !== undefined) {
        this.breadcrumbItems = [
          {
            text: 'users',
            to: '/users',
          },
          {
            text: this.account.name,
            to: `/users/${this.$route.params.userId}`,
          },
          {
            text: 'landings',
            active: true,
          },
        ]
      } else {
        this.breadcrumbItems = [
          {
            text: 'Landings',
            active: true,
          },
        ]
      }
    },
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || []
      this.setBreabcrumbs()
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
